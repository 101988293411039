// Import the configured Axios instance
import axios from "../../../axios";
// import axios from "axios";

import {
  RegisterUserData,
  LoginUserData,
  EditUserData,
  ChangePasswordData,
  RoleUgrade,
  ResetToken,
  GoogleLoginPayload,
} from "./authInterfaces";

const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL_PROD;
export const USER_API_URL = `${backendUrl}/api/`;

// REGISTER USER
const register = async (userData: RegisterUserData) => {
  const response = await axios.post(USER_API_URL + "auth/signup", userData);
  return response.data;
};

// SEND VERIFICATION EMAIL AFTER SIGN UP
const sendVerificationEmail = async () => {
  const response = await axios.post(
    USER_API_URL + "auth/email/sendverify-emailtoken"
  );
  return response.data;
};

// CONFIRM ACCOUNT FROM EMAIL LINK
const verifyAccountFromEmail = async (verifyAccountToken: string) => {
  const response = await axios.patch(
    USER_API_URL + "auth/verifyuseraccount/" + verifyAccountToken
  );
  return response.data;
};

// LOG IN USER
const signin = async (userData: LoginUserData) => {
  const response = await axios.post(USER_API_URL + "auth/signin", userData);
  return response.data;
};

// SIGNOUT USER || LOGOUT
const signOut = async () => {
  const response = await axios.get(USER_API_URL + "auth/signout");
  return response.data.message;
};

// EMAIL TOKEN || FORGET PASSWORD
const forgetPasswordEmailResetToken = async (Data: ResetToken) => {
  const response = await axios.post(
    USER_API_URL + "auth/email/forgotpasswordresettoken",
    Data
  );
  return response.data;
};

// RESET PASSWOED FROM EMAIL TOKEN
const resetPasswordFromEmail = async (
  Data: ResetToken,
  resetEmailToken: string
) => {
  const response = await axios.patch(
    USER_API_URL + "auth/resetpassword/" + resetEmailToken,
    Data
  );
  return response.data;
};

// CHECK USER LOGIN STATUS
const getLoginStatus = async () => {
  try {
    const response = await axios.get(USER_API_URL + "auth/isloggedin");
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error checking login status:", error);
    throw error;
  }
};

// GET USER PROFILE
const getUserProfileService = async () => {
  const response = await axios.get(USER_API_URL + "user/getuser");
  return response.data;
};

// UPDATE USER PROFILE
const updateMyProfileService = async (userData: EditUserData) => {
  const response = await axios.patch(
    USER_API_URL + "user/updateUser",
    userData
  );
  return response.data;
};

// CHANGE USER PASSWORD
const changeMyPasswordService = async (userData: ChangePasswordData) => {
  const response = await axios.patch(
    USER_API_URL + "user/changemypassword",
    userData
  );
  return response.data;
};

// DELETE MY ACCOUNT || USER
const deleteMyAccount = async () => {
  const response = await axios.delete(USER_API_URL + "user/deletemyaccount/");
  return response.data;
};

// *****************  *****************  //
// ADMIN PANEL || GET ALL USER
const getAllUserData = async () => {
  const response = await axios.get(USER_API_URL + "user/getusers");
  return response.data;
};

// ADMIN PANEL || // UPGRADE USER ROLE
const upgradeUser = async (userData: RoleUgrade) => {
  const response = await axios.patch(
    USER_API_URL + "user/upgraderole",
    userData
  );
  return response.data;
};

// ADMIN PANEL || // DELETE USER
const deleteUser = async (userID: string) => {
  const response = await axios.delete(USER_API_URL + "user/delete/" + userID);
  return response.data;
};

// THIRD PARTY - GOOGLE AUTHENTICATION
export const loginWithGoogle = async (userToken: GoogleLoginPayload) => {
  try {
    const response = await axios.post(
      USER_API_URL + "auth-google/callback",
      userToken
    );
    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error("Failed!!" + error);
  }
};

//  ***********  EXPORTING ***********  //
const authServices = {
  register,
  sendVerificationEmail,
  verifyAccountFromEmail,
  signin,
  signOut,
  forgetPasswordEmailResetToken,
  resetPasswordFromEmail,
  getLoginStatus,
  getUserProfileService,
  updateMyProfileService,
  changeMyPasswordService,
  deleteMyAccount,
  getAllUserData,
  upgradeUser,
  deleteUser,
  loginWithGoogle,
};

export default authServices;
