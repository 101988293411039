"use client";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import authService from "./authServices";

import {
  RegisterUserData,
  CustomError,
  LoginUserData,
  EditUserData,
  UserData,
  ChangePasswordData,
  FilterUsersPayload,
  RoleUgrade,
  ResetToken,
  GoogleLoginPayload,
} from "./authInterfaces";

import { toast } from "react-toastify";
import { RootState } from "../../Store";

const initialState = {
  isLoggedIn: false,
  user: {} as UserData,
  users: [] as UserData[],
  filteredUsers: [] as UserData[],
  isErrorUser: false,
  isSuccessUser: false,
  isLoadingUser: false,
  message: "" as string,
  verifiedUsers: 0,
  loggedInUsers: 0,
  suspendedUsers: 0,
  newUsers: 0, // a week ago new user
};

// REGISTER USER
export const registerUser = createAsyncThunk(
  "auth/register",
  async (userData: RegisterUserData, thunkAPI) => {
    try {
      const response = await authService.register(userData);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// SEND VERIFICATION EMAIL AFTER SIGN UP
export const sendVerificationEmail = createAsyncThunk(
  "auth/send-verification-email",
  async (_, thunkAPI) => {
    try {
      const response = await authService.sendVerificationEmail();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// SEND VERIFICATION EMAIL AFTER SIGN UP
export const verifyAccountFromEmail = createAsyncThunk(
  "auth/verify-account",
  async (payload: { verifyAccountToken: string }, thunkAPI) => {
    try {
      const { verifyAccountToken } = payload;
      const response = await authService.verifyAccountFromEmail(
        verifyAccountToken
      );
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// LOG IN USER
export const loginUser = createAsyncThunk(
  "auth/loginn",
  async (userData: LoginUserData, thunkAPI) => {
    try {
      const response = await authService.signin(userData);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// LOG OUT USER ACTION
export const signOutUser = createAsyncThunk(
  "auth/signout",
  async (_, thunkAPI) => {
    try {
      const response = await authService.signOut();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// EMAIL || FORGET PASSWORD
export const forgetPasswordEmailResetToken = createAsyncThunk(
  "auth/resetpassword-token",
  async (Data: ResetToken, thunkAPI) => {
    try {
      const response = await authService.forgetPasswordEmailResetToken(Data);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// RESET PASSWOED FROM EMAIL TOKEN
export const resetPasswordFromEmail = createAsyncThunk(
  "auth/resetpassword=fromemail",
  async (payload: { Data: ResetToken; resetEmailToken: string }, thunkAPI) => {
    try {
      const { Data, resetEmailToken } = payload;
      const response = await authService.resetPasswordFromEmail(
        Data,
        resetEmailToken
      );
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET LOGIN STATUS
export const getLoginStatus = createAsyncThunk(
  "auth/isloggedin",
  async (_, thunkAPI) => {
    try {
      const response = await authService.getLoginStatus();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET USER PROFILE
export const getUserProfile = createAsyncThunk(
  "auth/getuser",
  async (_, thunkAPI) => {
    try {
      const response = await authService.getUserProfileService();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// UPDATE MY Profile
export const updateMyProfile = createAsyncThunk(
  "auth/updateprofile",
  async (userData: EditUserData, thunkAPI) => {
    try {
      const response = await authService.updateMyProfileService(userData);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// CHANGE USER PASSWORD
export const changeMyPassword = createAsyncThunk(
  "auth/change-password",
  async (userData: ChangePasswordData, thunkAPI) => {
    try {
      const response = await authService.changeMyPasswordService(userData);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET USER PROFILE
export const deleteMyAccount = createAsyncThunk(
  "auth/delete-my-account",
  async (_, thunkAPI) => {
    try {
      const response = await authService.deleteMyAccount();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// *****************  *****************  //
// ADMIN PANEL || GET ALL USER
export const getAllUsers = createAsyncThunk(
  "auth/getallusersdata",
  async (_, thunkAPI) => {
    try {
      const response = await authService.getAllUserData();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ADMIN PANEL || // UPGRADE USER ROLE
export const changeUserRole = createAsyncThunk(
  "auth/ugrade-user",
  async (userData: RoleUgrade, thunkAPI) => {
    try {
      const response = await authService.upgradeUser(userData);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ADMIN PANEL || DELETE USER ACCOUNT
export const deleteUserAccount = createAsyncThunk(
  "auth/delete-user",
  async (userId: string, thunkAPI) => {
    try {
      const response = await authService.deleteUser(userId);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// THIRD PARTY - GOOGLE AUTHENTICATION
export const LoginWithGoogle = createAsyncThunk<
  any, // The return type of the thunk
  GoogleLoginPayload,
  { rejectValue: string }
>("auth/google-auth", async (userToken, thunkAPI) => {
  try {
    const response = await authService.loginWithGoogle(userToken);
    // console.log(response);
    return response;
  } catch (error: unknown) {
    const err = error as CustomError;
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// ************* SLICES ************* //
// ************* SLICES ************* //
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    RESET(state) {
      state.isErrorUser = false;
      state.isSuccessUser = false;
      state.isLoadingUser = false;
      state.message = "";
    },
    CALC_VERIFIED_USER(state) {
      const array: boolean[] = state.users.map((user) => user.userIsVerified);
      let count = 0;
      array.forEach((item) => {
        // in the array we only have TRUE/FALSE
        if (item === true) {
          count += 1;
        }
      });
      state.verifiedUsers = count;
    },
    CALC_LOGGEDIN_USER(state) {
      const array: boolean[] = state.users.map(
        (user) => user.currentlyLoggedIn
      );
      let count = 0;
      array.forEach((item) => {
        // in the array we only have TRUE/FALSE
        if (item === true) {
          count += 1;
        }
      });
      state.loggedInUsers = count;
    },
    CALC_SUSPENDED_USER(state) {
      const array: string[] = state.users.map((user) => user.role);
      let count = 0;
      array.forEach((item) => {
        // in the array we have several roles
        if (item === "Suspend") {
          count += 1;
        }
      });
      state.suspendedUsers = count;
    },
    CALC_NEW_USERS(state) {
      const currentDate = new Date();
      const last7Days = new Date(
        currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
      ); // Subtract 7 days in milliseconds

      let count = 0;
      state.users.forEach((user) => {
        const createdAtDate = new Date(user.createdAt);

        if (createdAtDate >= last7Days) {
          count += 1;
        }
      });

      state.newUsers = count;
    },
    FILTER_USERS(state, action: PayloadAction<FilterUsersPayload>) {
      const { users, searchWord } = action.payload;
      const filterr = users.filter(
        (user) =>
          user.name.toLowerCase().includes(searchWord.toLowerCase()) ||
          user.email.toLowerCase().includes(searchWord.toLowerCase()) ||
          user.role.toLowerCase().includes(searchWord.toLowerCase()) ||
          user.experienceLevel.toLowerCase().includes(searchWord.toLowerCase())
      );
      state.filteredUsers = filterr;
    },
  },

  // *********** ************ //
  extraReducers: (builder) => {
    builder
      // ***REGISTER USER
      .addCase(registerUser.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.user = action.payload.user as UserData;
        toast.success(action.payload.message);
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        state.user = action.payload as UserData;
        toast.error(action.payload as string);
      })

      // SEND VERIFICATION EMAIL AFTER SIGN UP
      .addCase(sendVerificationEmail.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(sendVerificationEmail.fulfilled, (state, action) => {
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.message = action.payload.message;
        toast.success("Verification Email Sent");
      })
      .addCase(sendVerificationEmail.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // CONFIRM ACCOUNT FROM EMAIL LINK
      .addCase(verifyAccountFromEmail.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(verifyAccountFromEmail.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.message = action.payload.message;
        toast.success("Account Verified Succesfully");
      })
      .addCase(verifyAccountFromEmail.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // LOGINUSER
      .addCase(loginUser.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.user = action.payload.user;
        toast.success(action.payload.message);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        state.user = action.payload as UserData; // Type assertion
        // state.user = action.payload as UserData | null; // Type assertion;
        toast.error(action.payload as string);
      })

      // LOGOUT USER
      .addCase(signOutUser.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(signOutUser.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.user = {} as any;
        console.log(state.user);
        toast.success(action.payload);
      })
      .addCase(signOutUser.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as any;
        toast.error(action.payload as any);
      })

      // GET LOGIN STATUS
      .addCase(getLoginStatus.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(getLoginStatus.fulfilled, (state, action) => {
        state.isLoggedIn = action.payload;
        state.isSuccessUser = true;
        state.isLoadingUser = false;
      })
      .addCase(getLoginStatus.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
      })

      // EMAIL || FORGET PASSWORD
      .addCase(forgetPasswordEmailResetToken.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(forgetPasswordEmailResetToken.fulfilled, (state, action) => {
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        toast.success(action.payload.message);
      })
      .addCase(forgetPasswordEmailResetToken.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        toast.error(action.payload as string);
        state.message = action.payload as string;
      })

      // EMAIL || RESET PASSWORD FROM EMAIL TOKEN
      .addCase(resetPasswordFromEmail.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(resetPasswordFromEmail.fulfilled, (state, action) => {
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        toast.success(action.payload.message);
      })
      .addCase(resetPasswordFromEmail.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        toast.error(action.payload as string);
        state.message = action.payload as string;
      })

      // GET USER PROFILE
      .addCase(getUserProfile.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.user = action.payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
      })

      // UPDATE MY Profile
      .addCase(updateMyProfile.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(updateMyProfile.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.user = action.payload.updatedUser as UserData;
        toast.success(action.payload.message);
      })
      .addCase(updateMyProfile.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
      })

      // CHANGE USER PASSWORD
      .addCase(changeMyPassword.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(changeMyPassword.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.isSuccessUser = true;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(changeMyPassword.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // DELETE MY ACCOUNT
      .addCase(deleteMyAccount.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(deleteMyAccount.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.user = {} as any;
        state.isLoggedIn = false;
        state.isSuccessUser = true;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(deleteMyAccount.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // ADMIN || GET ALL USERS
      .addCase(getAllUsers.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      //UPGRADE USER ROLE BY ADMIN
      .addCase(changeUserRole.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(changeUserRole.fulfilled, (state, action) => {
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(changeUserRole.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      //DELETE USER BY ADMIN
      .addCase(deleteUserAccount.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(deleteUserAccount.fulfilled, (state, action) => {
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(deleteUserAccount.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // THIRD PARTY - GOOGLE AUTHENTICATION
      .addCase(LoginWithGoogle.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(LoginWithGoogle.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.isSuccessUser = true;
        state.isLoadingUser = false;
        state.user = action.payload.user;
        console.log(action.payload);
        toast.success(action.payload.message);
      })
      .addCase(LoginWithGoogle.rejected, (state, action) => {
        state.isErrorUser = true;
        state.isLoadingUser = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
        console.log(action.payload as string);
      });
  },
});

export const {
  RESET,
  CALC_VERIFIED_USER,
  CALC_SUSPENDED_USER,
  CALC_NEW_USERS,
  CALC_LOGGEDIN_USER,
  FILTER_USERS,
} = authSlice.actions;

export const isLoggedIn = (state: RootState): boolean => state.auth.isLoggedIn;
export const userProfile = (state: RootState): UserData => state.auth.user;
export const getFilteredUser = (state: RootState): UserData[] =>
  state.auth.filteredUsers;

export default authSlice.reducer;
